<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">订单管理</span>
      <le-export-btn :page-param="pageParam"></le-export-btn>
    </div>

    <fee-rule-modal
      :visible.sync="showFeeRuleModal"
      :order-id="selectedFeeOrder.orderId"
      :max-power="selectedFeeOrder.maxPower"
      :show-order-settings="false"
    />

    <el-card class="el-main">
      <le-search-form @reset="handlerRest" @search="handlerSearch">
        <le-input v-model="pageParam.params.orderId" type="number" label="订单编号" />
        <le-input v-model="pageParam.params.mobile" type="number" label="手机号码" />
        <le-input v-model="pageParam.params.stationName" label="站点名称" />
        <le-input v-model="pageParam.params.deviceCode" type="number" label="设备编号" />
        <le-input v-model="pageParam.params.endReason" label="结束原因" :maxlength="30" />
        <le-input
          v-model="pageParam.params.deviceReportStopReason"
          label="上报原因"
          :maxlength="30"
        />
        <le-select-local-search
          v-model="pageParam.params.payType"
          label="支付方式"
          :options="payMethods"
        />
        <le-select-local-search
          v-model="pageParam.params.companyId"
          label="所属商户"
          :options="agentList"
        />
        <le-select-local-search
          v-model="pageParam.params.secureServiceStatus"
          label="安心充电"
          :options="insureStatusOptions"
        />
        <le-select-local-search
          v-model="pageParam.params.status"
          label="订单状态"
          :options="options"
        />
        <le-date-range
          label="下单时间"
          :min-date.sync="pageParam.params.startTime"
          :max-date.sync="pageParam.params.endTime"
          :picker-options="pickerOptions"
        />
      </le-search-form>
      <le-pagview
        id="table_box"
        :page-param="pageParam"
        :fresh-cache-ctrl="freshCacheCtrl"
        @setData="setTableData"
      >
        <el-table
          v-sticky="{ parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
          style="width: 100%"
        >
          <el-table-column label="序号" type="index" />
          <el-table-column prop="orderId" label="订单编号" width="155" />
          <el-table-column prop="mobile" label="手机号码" width="150" />
          <el-table-column prop="companyName" label="所属商户" width="150" />
          <el-table-column prop="stationName" label="站点名称" width="230">
            <template slot-scope="{ row }">
              <el-link type="primary" @click.prevent="onStationDetail(row)">
                {{ row.stationName || "--" }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column label="设备编号" width="150">
            <template slot-scope="{ row }">
              <el-link type="primary" @click.prevent="onViewDeviceDetail(row)">
                {{ row.deviceCode }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column label="插座号">
            <template slot-scope="{ row }">{{ row.slot + 1 }}</template>
          </el-table-column>
          <el-table-column prop="payTypeText" label="支付方式" />
          <el-table-column label="消费金额">
            <template slot-scope="{ row }">
              <span>{{ row.payStatus !== 0 ? util.numFormat(row.amount) : "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="选择充电时间">
            <template slot-scope="{ row }">
              <span>{{ row.planMinutes }}分钟</span>
            </template>
          </el-table-column>
          <el-table-column label="实际充电时间">
            <template slot-scope="{ row }">
              <span>{{ row.realMinutes }}分钟</span>
            </template>
          </el-table-column>
          <el-table-column prop="secureServiceStatus" label="安心充电" width="150">
            <template slot-scope="{ row }">
              <span>{{ insureStatusOptionsMap[row.secureServiceStatus] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="订单状态" width="150">
            <template slot-scope="{ row }">
              <span>{{ optionsMap[row.status] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="开始时间" width="200">
            <template slot-scope="{ row }">
              <span>{{ row.createTimeText || "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="结束时间" width="200">
            <template slot-scope="{ row }">
              <span>{{ row.endTimeText || "" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="endReason" label="结束原因" />
          <el-table-column prop="deviceReportStopReason" label="上报原因" />
          <el-table-column fixed="right" label="操作" width="160">
            <template slot-scope="{ row }">
              <div class="a-flex-rcc">
                <el-link type="primary" @click.prevent="handlerGoDetail(row.orderId)">详情</el-link>
                <el-link
                  v-if="row.canRefund"
                  type="primary"
                  class="a-ml-10"
                  @click.prevent="refund(row)"
                >
                  退款
                </el-link>
                <el-link type="primary" class="a-ml-10" @click="onClickFeeRule(row)">
                  计费规则
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </el-card>
    <refund-modal
      :visible.sync="showRefundModal"
      :order-info="selectedOrder"
      @success="hanldeRefundSuccess"
    />
  </div>
</template>

<script>
import { orderTransform } from "@charge/common";
import axios from "axios";
import { Message } from "element-ui";

import FeeRuleModal from "@/components-new/FeeRuleModal/index.vue";

import util from "../../utils/util.js";
import RefundModal from "./components/RefundModal.vue";
import { INSURE_STATUS, ORDER_STATUS, PAY_METHOD } from "./status";

export default {
  name: "OrderList",
  components: {
    FeeRuleModal: FeeRuleModal,
    RefundModal: RefundModal,
  },
  data() {
    return {
      showRefundModal: false,
      selectedOrder: {},
      selectedFeeOrder: {},
      showFeeRuleModal: false,

      util: util,
      tableData: [],
      pageParam: {
        url: this.$Config.apiUrl.getOrderList,
        method: "post",
        params: {
          mobile: "",
          deviceCode: "",
          startTime: "",
          endTime: "",
          orderId: "",
          stationName: "",
          status: "",
          secureServiceStatus: "",
          companyId: "",
          payType: "",
          endReason: "",
          deviceReportStopReason: "",
        },
        freshCtrl: 1,
      },
      freshCacheCtrl: 1,
      // 商户列表
      agentList: [],
    };
  },
  computed: {
    payMethods() {
      return PAY_METHOD || [];
    },
    payMethodMap() {
      return this.payMethods.reduce((map, item) => {
        map[item.value] = item.label;
        return map;
      }, {});
    },
    options() {
      return ORDER_STATUS || [];
    },
    optionsMap() {
      return this.options.reduce((map, item) => {
        map[item.value] = item.label;
        return map;
      }, {});
    },
    insureStatusOptions() {
      return INSURE_STATUS || [];
    },
    insureStatusOptionsMap() {
      return this.insureStatusOptions.reduce((map, item) => {
        map[item.value] = item.label;
        return map;
      }, {});
    },
    pickerOptions() {
      return {
        shortcuts: [
          {
            text: "全部",
            onClick: (picker) => {
              picker.$emit("pick", []);
            },
          },
          {
            text: "今日",
            onClick: (picker) => {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          },
          {
            text: "近七天",
            onClick: (picker) => {
              const today = new Date();
              const lastWeek = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);
              picker.$emit("pick", [lastWeek, today]);
            },
          },
        ],
      };
    },
  },
  created() {
    axios.all([this.getAgentList()]);
  },
  methods: {
    onClickFeeRule(row) {
      if (row.payType === 6) {
        Message({
          message: "无计费信息，该订单为套餐卡支付",
          type: "error",
          duration: 3 * 1000,
        });
        return;
      }
      this.showFeeRuleModal = true;
      this.selectedFeeOrder = row;
    },

    // 获取商户列表
    getAgentList() {
      return new Promise((resolve) => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.getAgentList,
          })
          .then((res) => {
            this.agentList = (res.data || []).map((item) => ({
              ...item,
              label: item.name,
              value: item.id,
            }));
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    //获取车辆列表
    setTableData(data) {
      this.tableData = data.map(orderTransform.enrichOrder);
    },

    handlerRest() {
      this.pageParam.params = {
        mobile: "",
        deviceCode: "",
        startTime: "",
        endTime: "",
        orderId: "",
        stationName: "",
        status: "",
      };
      this.pageParam.freshCtrl++;
    },

    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    handlerGoDetail(id) {
      this.$router.push({
        path: "/order/order-detail",
        query: {
          orderId: id,
        },
      });
    },
    //订单退款
    refund(row) {
      this.selectedOrder = row;
      this.showRefundModal = true;
    },
    onStationDetail(row) {
      if (!row.stationId) {
        return this.$message.warning("站点出错，请联系管理员");
      }
      this.$router.push({
        path: "/station/station-detail",
        query: {
          stationId: row.stationId,
        },
      });
    },

    onViewDeviceDetail(row) {
      if (!row.deviceCode) {
        return this.$message.warning("设备出错，请联系管理员");
      }
      this.$router.push({
        path: "/device/device-info",
        query: {
          deviceCode: row.deviceCode,
        },
      });
    },

    hanldeRefundSuccess() {
      this.freshCacheCtrl += 1;
    },
  },
};
</script>

<style scoped></style>
